
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import UserAvatar from '@/components/UserAvatar.vue';

export default Vue.extend({
    name: 'TheAccountNavigation',
    components: {
        UserAvatar,
    },
    props: {
        icons: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    methods: {
        async logout() {
          await this.$store.dispatch('accountLogOut');
      },
    },
});
