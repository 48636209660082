import { BASE_API_URL } from '@/config';
import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { getAuthHeader } from 'isc-shared/auth';

const baseHeaders = {
    'Content-Type': 'application/json',
    'accept': 'application/json',
    'X-Preferred-System-Of-Measure': localStorage.preferredSystemOfMeasure, // TODO: Should be in Capacitor Storage.
};

export const apiClient = axios.create({
    baseURL: BASE_API_URL,
    headers: baseHeaders,
});

apiClient.interceptors.request.use(async config => {
    if (!config.headers) {
        config.headers = {};
    }

    const legacyAuthToken = await Preferences.get({ key: 'auth-token' });
    const identityServiceAuthHeader = await getAuthHeader();
    if (legacyAuthToken.value) {
        config.headers['Authorization'] = `Bearer ${legacyAuthToken.value}`;
    } else if (identityServiceAuthHeader) {
        Object.assign(config.headers, identityServiceAuthHeader);
    }
    return config;
}, error => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(response => {
    return response;
}, async error => {
    const tokenIsExpired = error.response?.status === 401 && error.response.data.error?.toUpperCase().includes('TOKEN EXPIRED');

    if (tokenIsExpired) {
        import('@/store').then(({ default: store }) => {
            console.error('Token expired');
            store.dispatch('accountLogOut');
            store.dispatch('alertUser', {
                type: 'error',
                message: 'account.signInAgain',
                suppressFurtherErrors: true,
            });
        });
    }

    return Promise.reject(error);
});

export async function updateAuthToken(token: string | null, expiration?: Date): Promise<void> {
    if (token && expiration) {
        await Promise.all([
            Preferences.set({ key: 'auth-token', value: token }),
            Preferences.set({ key: 'auth-token-expires', value: expiration.toISOString() })
        ]);
    } else {
        await Promise.all([
            Preferences.remove({ key: 'auth-token' }),
            Preferences.remove({ key: 'auth-token-expires' })
        ]);
    }
}

export async function authTokenExpiresBefore(date: Date): Promise<boolean | null> {
    const expiration = await Preferences.get({ key: 'auth-token-expires' });
    if (expiration.value) {
        const expirationDate = new Date(expiration.value);
        return expirationDate < date;
    } else {
        return null;
    }
}
